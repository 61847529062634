import { NoImageCardIconOrigin, SaveIcon } from "core/components/common/icons";
import styled from "styled-components";

export { default as AddIcon } from "core/public/icon/add.svg";
export { default as Arrow2Icon } from "core/public/icon/arrow-2.svg";
export { default as ArrowUpIcon } from "core/public/icon/arrow-up.svg";
export { default as ArrowIcon } from "core/public/icon/arrow.svg";
export { default as AttachIcon } from "core/public/icon/attach.svg";
export { default as BarcodeIcon } from "core/public/icon/barcode.svg";
export { default as BellIcon } from "core/public/icon/bell.svg";
export { default as BuildingIcon } from "core/public/icon/building.svg";
export { default as CalendarIcon } from "core/public/icon/calendar.svg";
export { default as CheckboxIcon } from "core/public/icon/checkbox.svg";
export { default as CheckedIcon } from "core/public/icon/checked.svg";
export { default as CloseIcon } from "core/public/icon/close.svg";
export { default as CollapseIcon } from "core/public/icon/collapse.svg";
export { default as CopyIcon } from "core/public/icon/copy.svg";
export { default as CustomerIcon } from "core/public/icon/customer-management.svg";
export { default as DeleteIcon } from "core/public/icon/delete.svg";
export { default as DeliveryIcon } from "core/public/icon/delivery.svg";
export {
  default as Download2Icon,
  default as DownloadIcon,
  default as SaveIcon
} from "core/public/icon/download2Icon.svg";
export { default as EditIcon } from "core/public/icon/edit.svg";
export { default as EmailIcon } from "core/public/icon/email.svg";
export { default as ExcelIcon } from "core/public/icon/excel.svg";
export { default as FactoryIcon } from "core/public/icon/factory.svg";
export { default as FolderNewIcon } from "core/public/icon/folder-new.svg";
export { default as FolderIcon } from "core/public/icon/folder.svg";
export { default as FolderCloseIcon } from "core/public/icon/folder_close.svg";
export { default as FolderHomeIcon } from "core/public/icon/folder_home.svg";
export { default as FolderOpenIcon } from "core/public/icon/folder_open.svg";
export { default as GroupLogo } from "core/public/icon/group-logo.svg";
export { default as GuideIcon } from "core/public/icon/guide.svg";
export { default as HomepageIcon } from "core/public/icon/homepage.svg";
export { default as HumanIcon } from "core/public/icon/hr.svg";
export { default as ImageIcon } from "core/public/icon/image.svg";
export { default as ImgDeleteIcon } from "core/public/icon/img-delete.svg";
export { default as LinkIcon } from "core/public/icon/link.svg";
export { default as LockWhiteIcon } from "core/public/icon/lock-white.svg";
export { default as LockIcon } from "core/public/icon/lock.svg";
export { default as MaterialIcon } from "core/public/icon/material.svg";
export { default as MinusIcon } from "core/public/icon/minusIcon.svg";
export { default as NewCheckboxActiveIcon } from "core/public/icon/new-checkbox-active.svg";
export { default as NewCheckboxIcon } from "core/public/icon/new-checkbox.svg";
export { default as NewWindow } from "core/public/icon/new-window.svg";
export { default as NoImageIcon } from "core/public/icon/noImage.svg";
export { default as NoticeIcon } from "core/public/icon/notice.svg";
export { default as NoImageCardIconOrigin } from "core/public/icon/no_image.svg";
export { default as OpenIcon } from "core/public/icon/open.svg";
export { default as OrderIcon } from "core/public/icon/order.svg";
export { default as PlusIcon } from "core/public/icon/plusIcon.svg";
export { default as PrintIcon } from "core/public/icon/print.svg";
export { default as RadioIcon } from "core/public/icon/radio.svg";
export { default as RadioActiveIcon } from "core/public/icon/radio_active.svg";
export { default as RefreshIcon } from "core/public/icon/refresh.svg";
export { default as ResetIcon } from "core/public/icon/reset.svg";
export { default as ResourceIcon } from "core/public/icon/resource.svg";
export { default as SaveWhiteIcon } from "core/public/icon/save-white.svg";
export { default as SearchIcon } from "core/public/icon/search.svg";
export { default as SmsIcon } from "core/public/icon/sms.svg";
export { default as StatisticsIcon } from "core/public/icon/statistic.svg";
export { default as StatsIcon } from "core/public/icon/stats.svg";
export { default as TreeCaretIcon } from "core/public/icon/treeCaret.svg";
export { default as TreeCaretArrowIcon } from "core/public/icon/treeCaretArrow.svg";
export { default as TreeCaretCloseIcon } from "core/public/icon/treeCaretClose.svg";
export { default as TreeFolderIcon } from "core/public/icon/treeFolder.svg";
export { default as TreeFolderCloseIcon } from "core/public/icon/treeFolderClose.svg";
export { default as TreeFolderItemIcon } from "core/public/icon/treeFolderItem.svg";
export { default as TreePersonIcon } from "core/public/icon/treePerson.svg";
export { default as AllIcon } from "core/public/icon/type=all.svg";
export { default as SettingsIcon } from "core/public/icon/type=settings.svg";
export { default as UploadIcon } from "core/public/icon/upload2Icon.svg";
export { default as ZoomInIcon } from "core/public/icon/zoom-in.svg";
// export { default as FigureIcon } from 'core/public/icon/_icon.svg';
export { default as FigureIcon } from "core/public/icon/_icon.svg";

export const WhiteSaveIcon: any = styled(SaveIcon)`
  & > path {
    fill: #fff;
  }
`;

export const NoImageCardIcon: any = styled(NoImageCardIconOrigin)<{
  error?: boolean;
}>`
  color: ${({ error }) => (error ? "red" : "#9F9F9F")};
`;
