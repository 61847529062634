import { localStorageEffect } from "core/utils/localStorage";
import { atom } from "recoil";

//2. sessionStorage에 저장하고 싶은 경우
//Next.js를 쓴다면 sessionStorage는 아래와 같이 따로 설정 필요
/* const sessionStorage =
      typeof window !== 'undefined' ? window.sessionStorage : undefined
const { persistAtom } = recoilPersist({
  key: '내맘대로 정하는 키 이름',
  storage: sessionStorage,
}); */

export interface userInbfoTypes {
  state: boolean;
  message: string;
  staff_id: string;
  photo_file: string;
  staff_name: string;
  org_no: string;
  class_position: string;
  duty_position: string;
  admin_org_no: string;
  erp: any;
  is_master: boolean;
  is_outsourcing?: boolean;
  office_phone: string;
  company: string;
  userType?: string;
  permissions?: string;
}

// 사이드바 오픈 여부
export const loginUserState = atom<userInbfoTypes>({
  key: `loginUserState`,
  default: {
    state: false,
    message: "",
    staff_id: "",
    photo_file: "",
    staff_name: "",
    org_no: "",
    class_position: "",
    duty_position: "",
    admin_org_no: "",
    erp: [],
    is_master: false,
    is_outsourcing: false,
    office_phone: "",
    company: "",
    userType: "",
    permissions: ""
  },
  effects: [localStorageEffect("loginUserState")]
});
