import styled, { css } from "styled-components";
import Pagination from "./common/Pagination";

export const PaginationWrapper: any = styled(Pagination)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 64px;
`;

// 컨텐츠 영역
export const TitleArea: any = styled.div<{ searchArea?: boolean }>`
  background-color: white;
`;

// 컨텐츠 영역
export const Main: any = styled.div`
  background-color: white;
  padding: 24px;
  padding-top: 0px;

  height: 100vh;
  overflow: auto;
  overflow: hidden;
`;

//검색 영역
export const SearchWrapper: any = styled.section`
  display: flex;
  // background: #f9f9f9;
  // border-bottom: 1px solid #cccccc;
  border-radius: 8px;
  justify-content: space-between;
  padding: 0px 16px;
  padding-bottom: 16px;
`;

//검색 영역 전체
export const SearchSectionWrapper: any = styled.section`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

//검색 영역 하나
export const SearchSection: any = styled.div`
  display: flex;

  // width: 100%;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 8px;
  min-width: fit-content;

  justify-content: center;

  &:first-child {
    justify-content: flex-start;
  }
  &:last-child {
    justify-content: flex-end;
  }

  & > div {
    min-width: 120px;
    // width: 100%;
  }
  & > input[type="text"],
  .staffSearchInput {
    min-width: 120px;
    margin-left: 8px;
    // max-width: 160px;
  }
  & > .selectComp {
    width: 100%;
    // max-width: 216px;

    & > button {
      height: 32px;
    }
  }
  & > .datePickerComp {
    max-width: 126px;
  }
`;
//검색 라벨
export const SearchLabel: any = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.black222};
  min-width: fit-content;
`;

//검색버튼영역
export const SearchButtonSection: any = styled.div`
  display: flex;

  // flex: 1;
  width: auto;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 8px;
`;

export const StyledATag: any = styled.a`
  color: ${({ theme }) => theme.colors.primary} !important;
  &:hover {
    text-decoration: underline;
  }
  cursor: pointer;
`;

export const StyledText: any = styled.h1<{
  color?: string;
  size?: string;
  weight?: string;
  lineHeight?: string;
  type?: "title" | "subTitle" | "bold";
}>`
  font-weight: 400;
  font-size: 13px;
  line-height: ${({ lineHeight }) => lineHeight || "16px"};
  font-family: Pretendard;
  color: ${({ theme }) => theme.colors.black222};

  ${(props) =>
    props.type === "bold" &&
    css`
      font-weight: 700;
    `};

  ${(props) =>
    props.type === "title" &&
    css`
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
    `};
  ${(props) =>
    props.type === "subTitle" &&
    css`
      // font-size: 15px;
      // line-height: 18px;
      color: ${({ theme }) => theme.colors.primary};
      vertical-align: bottom;
    `};

  font-weight: ${({ weight }) => weight};
  font-size: ${({ size }) => size}px;
  color: ${({ color }) => color};
`;

export const StyledBottom: any = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0px;
  height: 72px;
  padding: 16px 24px;
  border-top: 1px solid #ccc;
  background-color: #fff;
  display: flex;
  gap: 8px;
`;

export const StyleColumnForm: any = styled.table`
  width: 100%;
  border: 1px solid #ccc;
  border-collapse: separate !important;
  border-radius: 8px;
  table-layout: auto;
  table-layout: fixed;
  & tr {
    & > th {
      text-align: left;
      padding: 12px 16px 12px 16px;
      background-color: #f9f9f9;
      color: #222;
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;

      border-bottom: 1px solid #ccc;
      border-right: 1px solid #ccc;

      :first-child {
        border-start-end-radius: 8px;
      }
      :last-child {
        border-start-start-radius: 8px;
        border-right: 0px;
      }
    }

    & > td {
      border-bottom: 1px solid #ccc;
      border-right: 1px solid #ccc;
      padding: 8px;
      font-family: "Pretendard";
      font-style: normal;
      font-size: 13px;
      line-height: 16px;

      &:last-child {
        border-right: 0px;
      }
    }
    &:nth-child(n + 2) {
      &:last-child {
        & > th:first-child {
          border-bottom: 0px;
          border-radius: 0 0 0 8px;
        }

        & > td {
          border-bottom: 0px;
        }
      }
    }
  }
`;

export const Division: any = styled.div`
  height: 80%;
  width: 1px;
  background-color: #ccc;
`;
export const PartWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100vh;
  padding: 0px 16px 16px 16px;
`;
export const PartHeader: any = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 24px 8px 24px 8px;
  align-items: center;
  gap: 8px;
`;

export const PartHeaderText: any = styled.div`
  color: var(--black, #222);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  flex: 1;
`;

export const PartHeaderButtonBox: any = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const Part: any = styled.div`
  border: 1px solid #9f9f9f;
  border-radius: 8px;
  display: flex;
  flex: 1;
  height: 70%;
  max-height: 70%;
  width: 100%;
  /* overflow-y: auto; */
`;

export const BottomDiv: any = styled.div`
  display: flex;
  // margin: -16px;
  padding: 16px;
  height: 100%;

  & > div:not(.dividerDiv) {
    display: flex;
    flex-direction: column;
    flex: 1;

    padding: 0px 16px;
    &:first-child {
      padding-left: 0px;
    }
    &:last-child {
      padding-right: 0px;
    }
  }
`;
