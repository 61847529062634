import { message } from 'antd';



const ToastMessage = (content: string, style = {}) => {

  message.config({
    top : 100,
    maxCount : 1
  })
  return message.error({
    content:content,
    className: 'custom-class',
    duration: 5,
    style:{
      fontSize : 16,
      ...style,
    },
 icon: <></>,
  });
}





export default ToastMessage;
