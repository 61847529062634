import axios from "axios";
import Config from "../env/Config";

axios.defaults.withCredentials = true;
const API_SERVER_BASE_URL = Config.publicClientUrl;

axios.interceptors.request.use(
  function (config) {
    config.baseURL = API_SERVER_BASE_URL + "/api/";
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const get = async (url: string) => {
  const data = await axios
    .get(`${API_SERVER_BASE_URL}${url}`)
    .then((response) => response.data);
  return data;
};

export const fullUrlGet = async (url: string) => {
  const TMP_API_SERVER_BASE_URL = "";
  const data = await axios
    .get(`${TMP_API_SERVER_BASE_URL}${url}`)
    .then((response) => response.data);
  return data;
};

//insert
export const post = async (url: string, payload: any, options?: any) => {
  //임시로 staff_id : msk2417
  let repayload = { ...payload, staff_id: payload.staff_id || "msk2417" };

  if (url.indexOf("change_password") !== -1)
    repayload = { ...repayload, staff_id: "" };

  const data = await axios
    .post(`${API_SERVER_BASE_URL}${url}`, repayload, options)
    .then((response) => response.data);
  return data;
};

//full rul insert
export const fullUrlPost = async (url: string, payload: any, options?: any) => {
  let repayload = { ...payload, staff_id: "nsn" };
  const TMP_API_SERVER_BASE_URL = "";
  const data = await axios
    .post(`${TMP_API_SERVER_BASE_URL}${url}`, repayload, options)
    .then((response) => response.data);
  return data;
};
//update
export const put = async (url: string, payload: any, options?: any) => {
  const data = await axios
    .put(`${API_SERVER_BASE_URL}${url}`, payload, options)
    .then((response) => response.data);
  return data;
};

//update for one
export const patch = async (url: string, payload: any, options?: any) => {
  const data = await axios
    .patch(`${API_SERVER_BASE_URL}${url}`, payload, options)
    .then((response) => response.data);
  return data;
};

//delete
export const removeOne = async (url: string) => {
  const data = await axios
    .delete(`${API_SERVER_BASE_URL}${url}`)
    .then((response) => response.data);
  return data;
};

export const removeMany = async (url: string, payload: any) => {
  const data = await axios
    .post(`${API_SERVER_BASE_URL}${url}`, payload)
    .then((response) => response.data);
  return data;
};
