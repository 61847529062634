/* eslint-disable */
export default {
  publicAuthBaseUrl: process.env.NEXT_PUBLIC_AUTH_BASE_URL,
  publicAdpiaBaseUrl: process.env.NEXT_PUBLIC_ADPIA_BASE_URL,
  publicAdpiaOrderBaseUrl: process.env.NEXT_PUBLIC_ADPIA_ORDER_BASE_URL,
  publicAdpiaMallBaseUrl: process.env.NEXT_PUBLIC_ADPIAMALL_BASE_URL,
  publicStayrakBaseUrl: process.env.NEXT_PUBLIC_STAYRAK_BASE_URL,
  publicClientUrl: process.env.NEXT_PUBLIC_CLIENT_BASE_URL,
  publicServerUrl: process.env.NEXT_PUBLIC_SERVER_BASE_URL,
  publicBaseUploadUrl: [
    process.env.NEXT_PUBLIC_SERVER_BASE_URL
      ? process.env.NEXT_PUBLIC_SERVER_BASE_URL
      : process.env.NEXT_PUBLIC_CLIENT_BASE_URL,
    "uploads"
  ].join("/"),
  publicDevBaseUrl: process.env.NEXT_PUBLIC_DEV_BASE_URL,
  publicRealBaseUrl: process.env.NEXT_PUBLIC_REAL_BASE_URL,
  publicSentryDsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

  tokenExpireTime: process.env.NEXT_PUBLIC_TOKEN_EXPIRE_TIME
};
