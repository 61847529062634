import { ResCodeInfo } from "core/models/Common";
import { ListItem } from "core/components/common/Select";
import { format } from "date-fns";
import { EventHandler, ReactEventHandler, SyntheticEvent } from "react";

export const uuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

type WrapEventFunction<T> = (
  userEvent?: EventHandler<SyntheticEvent<T>>,
  proxyEvent?: EventHandler<SyntheticEvent<T>>
) => ReactEventHandler<T>;

export const wrapEvent: WrapEventFunction<unknown> = (
  userEvent,
  proxyEvent
) => {
  return (event) => {
    try {
      proxyEvent?.(event);
    } finally {
      userEvent?.(event);
    }
  };
};

export const fileToDataURL = (file: File) => {
  const reader = new FileReader();

  return new Promise<BlobImage>((res, rej) => {
    reader.onload = function onload(event) {
      res({
        source: event.target?.result.toString(),
        name: file.name,
        size: file.size,
        type: file.type,
      });
    };
    reader.readAsDataURL(file);
  });
};

export const imageUrlToBlob = (url: string) => {
  return new Promise<Blob>((res, rej) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function onload() {
      if (xhr.status === 200) {
        res(xhr.response);
      } else {
        rej(xhr.statusText);
      }
    };
    xhr.onerror = function onerror() {
      rej(xhr.statusText);
    };
    xhr.send();
  });
};

// export const formItemSetting =(model:any, )
export interface BlobImage {
  source?: string;
  name: string;
  size: number;
  type: string;
}

export const NOOP = () => {};

type CleanObject = <T>(obj: T) => T;
export const cleanObject: CleanObject = (obj) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value) {
      const keyName = key as keyof typeof obj;
      acc[keyName] = value;
    }
    return acc;
  }, {} as typeof obj);

  return obj;
};
