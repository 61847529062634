export enum DelType {
  ZERO = "0", //정상
  ONE = "1" //삭제
}

export enum CodeType {
  RANK = "CLP", //직위
  DUTY = "DUP", //직책
  WORK_TYPE = "SWT", //업무구분
  WORK = "EWT", //직무
  OFFICE_POSITION = "OFP", //근무처위치
  EMP_TYPE = "EMP", //고용형태 / 경력구분
  SALARY_TYPE = "SLT", //연봉타입
  COMPANY_INFO = "CPC", //사업장
  WORK_STATE = "PAS", //발령상태
  OTT = "OTT" //추가근무
}

export enum CompanyType {
  COMPANY_ID = "company_id",
  COM_NAME = "com_name"
}

export enum CompanyDivision {
  OUTSOURCING = "외주업체",
  AS = "AS"
}

export enum UserInformationType {
  //https://docs.google.com/spreadsheets/d/11C-OvYd1Vt9NFhP52VYwPZY4Thg2fiaDhbTUasDKCvA/edit#gid=1753230686
  // 장비 or 사원
  DEPARTMENT = "HMC10",
  EQUIPMENT = "HMC20"
}

export enum CodeInfoType {
  CARD_LIST = "CDC" //카드사 목록 조회
}

export enum SalaryType {
  holiday_work_time = "휴일근로시간",
  night_work_time = "심야근로시간",
  overtime_work_time = "연장근로시간",
  work_time = "소정근로시간",

  default_pay = "기본급",
  comprehensive_pay = "시간외수당",
  overtime_work_pay = "연장근로수당",
  bonus_pay = "상여금1",
  night_work_pay = "심야수당1",
  default_night_work_pay = "심야수당2",
  holiday_work_pay = "휴일수당",

  missing_previous_month_pay = "전월착오수당",
  etc_pay = "기타수당",
  retrospective_pay = "소급분",
  annual_settlement_pay = "연차수당",

  income_tax = "소득세",
  resident_tax = "주민세",
  national_pension = "국민연금",
  health_insurance = "건강보험",
  long_term_care_insurance = "장기요양보험",
  employment_insurance = "고용보험",
  year_end_income_tax = "연말소득세",
  year_end_resident_tax = "연말주민세",

  health_insurance_additional = "건강보험정산",
  long_term_care_insurance_additional = "장기요양정산",
  student_loan_pay = "학자금공제",
  settlement_pay = "근태공제",
  etc_deduction = "기타공제"
}

export enum ModalType {
  Create = "Create", //생성
  Update = "Update", //수정
  Delete = "Delete", //삭제
  View = "View", //보기
  None = "None"
}

export enum DocumentApprovalListType {
  All = "all", //전체문서
  MyDoc = "mydoc", //내보관함
  Regist = "regist", //상신함
  Receive = "receive", //수신/참조함
  Accept = "accept", //기결함
  Reject = "reject", //반려함
  Undecid = "undecid", //미결함
  Implement = "implement" //시행함
}

export enum COMPANY_CODE {
  Adpia = "CPCSW", //애드피아
  AdpiaMall = "CPCSE", //애드피아몰
  FoodMall = "CPCSR" //푸드몰
}

export enum COMPANY_NO {
  Adpia = "103", //애드피아
  AdpiaMall = "103", //"203", //애드피아몰
  FoodMall = "211" //푸드몰
}
